import React, { useEffect, useState, useRef, Suspense } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import Footer from "../components/footer/footer";
import utils from "../components/utils";
import anim from "../components/masonry/animation";

import Modal from "@mui/material/Modal";
import pandemicImg from "../img/img_hospitals_web.jpg";
import gtaImg from "../img/img_GTA-min.jpeg";
import ehrImg from "../img/img_smart_ehr-min.jpeg";
import picarro from "../img/img_digital_experience-min.png";
import taf from "../img/img_Visualizing the pulse.jpg";
import polycloud from "../img/img_Enterprise_Apps.jpg";
import closeIcon from "../img/close.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ContactUs from "../components/contactus/contactus";

export default function Work() {
  const first = useRef(null);
  const second = useRef(null);
  const third = useRef(null);
  const fourth = useRef(null);
  const fifth = useRef(null);
  const sixth = useRef(null);
  const firstImage = useRef(null);
  const secondImage = useRef(null);
  const thirdImage = useRef(null);
  const fourthImage = useRef(null);
  const fifthImage = useRef(null);
  const sixthImage = useRef(null);
  const acheive = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [modalValue, setModalValue] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [y, setY] = useState(0);
  const minscrollval = 100;
  const handleScroll = (e) => {
    var top = e.target.scrollTop;
    var VH = window.innerHeight;
    utils.handleHeaderScroll(y, top);
    setY(top);
    if (first.current && firstImage.current) {
      if (top > minscrollval) {
        first.current.className = "animate";
        firstImage.current.className = "solution-img pandemic animate";
      } else {
        first.current.className = "";
        firstImage.current.className = "solution-img pandemic";
      }
    }
    if (second.current && secondImage.current) {
      if (top > VH + minscrollval) {
        second.current.className = "animate opposite";
        secondImage.current.className = "solution-img gta animate";
      } else {
        second.current.className = "opposite";
        secondImage.current.className = "solution-img gta";
      }
    }
    if (third.current && thirdImage.current) {
      if (top > VH * 2 + minscrollval) {
        third.current.className = "animate";
        thirdImage.current.className = "solution-img ehr animate";
      } else {
        third.current.className = "";
        thirdImage.current.className = "solution-img ehr";
      }
    }
    if (fourth.current && fourthImage.current) {
      if (top > VH * 3 + minscrollval) {
        fourth.current.className = "animate opposite";
        fourthImage.current.className = "solution-img fleet animate";
      } else {
        fourth.current.className = "opposite";
        fourthImage.current.className = "solution-img fleet";
      }
    }
    if (fifth.current && fifthImage.current) {
      if (top > VH * 4 + minscrollval) {
        fifth.current.className = "animate";
        fifthImage.current.className = "solution-img italos animate";
      } else {
        fifth.current.className = "";
        fifthImage.current.className = "solution-img italos";
      }
    }
    if (sixth.current && sixthImage.current) {
      if (top > VH * 5 + minscrollval) {
        sixth.current.className = "animate opposite";
        sixthImage.current.className = "solution-img fleet2 animate";
      } else {
        sixth.current.className = "opposite";
        sixthImage.current.className = "solution-img fleet2";
      }
    }
    if (top > 0.9 * VH * 6 - 100) anim.wowanimatefadein(acheive, true);
    else anim.wowanimatefadeout(acheive, true);
  };
  const toContactPage = (e) => {
    window.location.href = "/contact";
  };

  const getImg = () => {
    switch (modalValue) {
      case 1:
        return taf;
      case 2:
        return pandemicImg;
      // case 1:
      //   return pandemicImg;
      // case 2:
      //   return gtaImg;
      case 3:
        return polycloud;
      // case 3:
      //   return ehrImg;
      case 4:
        return picarro;
      case 5:
        return ehrImg;
      case 6:
        return gtaImg;
    }
  };

  const getRelatedText = () => {
    switch (modalValue) {
      case 1:
        return (
          <p ref={first}>
            <h3>
              <b>Visualizing the pulse </b>of a large transformation program
            </h3>
            {/* <br />
            <br /> */}
            <>
              {hideContent !== 1 && (
                <>
                  Data from over 1,200 milestones, 30+ sources were{" "}
                  <b>crunched, simplified and visualized to a nutshell</b>, for
                  Executive consumption.
                  <br />
                  <br />
                  We make enterprise data useful and usable, by abstracting the
                  hidden story behind the data, and presenting it through
                  intuitive, interactive digital visualizations.
                  <br />
                  <br />
                </>
              )}
              <ContactUs
                title="Visualizing the pulse"
                setHideContent={setHideContent}
                index={1}
              ></ContactUs>
            </>
          </p>
        );

      case 2:
        return (
          <p ref={second} className="opposite">
            <h3>
              <b>Keeping people out of the </b>hospitals
            </h3>
            {/* <br />
            <br /> */}
            {hideContent !== 2 && (
              <>
                Our approach was to connect common health data and established
                medical principles to proactively identify potential health
                risks in individuals. We built a connected suite of apps which
                listen to health data of patients, risk stratify, and alert
                healthcare providers ahead of time, to avoid potential
                hospitalizations as much as possible.
                <br />
                <br />
                This application is used for preventive care, senior care,
                clinical trials, pandemic situations and many more.
                <br />
                <br />
              </>
            )}
            <ContactUs
              title="Keeping people out of the hospitals"
              setHideContent={setHideContent}
              index={2}
            ></ContactUs>
          </p>
        );

      case 3:
        return (
          <p ref={third}>
            <h3>
              <b>Consumerization of Enterprise </b>Apps
            </h3>
            {/* <br /> */}
            {hideContent !== 3 && (
              <>
                A comprehensive <b>multi-cloud environment management tool</b>{" "}
                with consumer-like user experience, designed for a leading
                technology solutions provider.
                <br />
                <br />
                Our team specializes in{" "}
                <b>
                  transforming complex business/technical functionalities
                </b>{" "}
                into consumer-like experiences for enterprise applications.
                <br />
                <br />
              </>
            )}
            <ContactUs
              title="Consumerization of Enterprise Apps"
              setHideContent={setHideContent}
              index={3}
            ></ContactUs>
          </p>
        );

      case 4:
        return (
          <p ref={fourth} className="opposite">
            <h3>
              <b>Digitizing Industrial </b>Products
            </h3>
            {/* <br /> */}
            {hideContent !== 4 && (
              <>
                Airborne molecular contaminants can seriously limit the
                productivity rate in hi-tech manufacturing.
                <b> Rhombuz designed the digital experience</b> for a leading
                AMC monitoring system, making it intuitive, user-friendly, and
                efficient, resulting in overall productivity improvement in fab
                units.
                <br />
                <br />
                We specialize in designing and building digital interfaces for
                industrial products, for user-friendliness, productivity
                improvement, and safety.
                <br />
                <br />
              </>
            )}
            <ContactUs
              title="Digitizing Industrial Products"
              setHideContent={setHideContent}
              index={4}
            ></ContactUs>
          </p>
        );

      case 5:
        return (
          <p ref={fifth}>
            <h3>
              <b>AI Powered </b>EHR
            </h3>
            {/* <br /> */}
            {hideContent !== 5 && (
              <>
                AI powered Electronic Health Record system which provide
                intelligent diagnosis and recommendations (prescriptions, tests
                and treatment plans)
                <br />
                <br />- <b>50% reduction</b> in Physician-Patient encounter time
                <br />- Improved the <b>quality and velocity</b> of healthcare
                services
                <br />- <b>New business models</b> for hospitals and clinics
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            <ContactUs
              title="AI Powered EHR"
              setHideContent={setHideContent}
              index={5}
            ></ContactUs>
            <br />
          </p>
        );
      case 6:
        return (
          <p ref={sixth} className="opposite">
            <h3>
              <b>Global Trade Analysis </b>Tool
            </h3>
            {/* <br /> */}
            {hideContent !== 6 && (
              <>
                A tool for corporations and policy makers to{" "}
                <b>analyze the economic impact</b> of global events, bilateral
                agreements, political changes, pandemic etc. on their business,
                region or country. The GTA tool combine global standard data and
                user inputs to generate economic forecast models for macro and
                micro level analysis. The tool is especially useful for
                enterprises and SMEs to{" "}
                <b>
                  assess the exposure of their business to global economic
                  shocks
                </b>{" "}
                and plan accordingly.
                <br />
                <br />
                This product was developed in partnership with{" "}
                <a href="http://www.infsum.com/en/" target="_blank">
                  {" "}
                  Infinite Sum Modeling
                </a>{" "}
                – a company focused on developing mathematical models for
                economic analysis and forecasting.
                <br />
                <br />
              </>
            )}
            <ContactUs
              title="Global Trade Analysis"
              setHideContent={setHideContent}
              index={6}
            ></ContactUs>
          </p>
        );
    }
  };

  const clickHandler = (e) => {
    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // if(isMobile)

    switch (e.target.id) {
      case "image-first":
        setModalValue(1);
        break;
      case "image-second":
        setModalValue(2);
        break;
      case "image-third":
        setModalValue(3);
        break;
      case "image-fourth":
        setModalValue(4);
        break;
      case "image-fifth":
        setModalValue(5);
        break;
      case "image-sixth":
        setModalValue(6);
        break;
    }
    return setOpen(true);
  };
  const isMacintosh = () => {
    return navigator.platform.indexOf("Mac") > -1;
  };

  const closeModal = () => {
    setOpen(false);
  };

  // contactus
  const [showContent, setShowContent] = useState(true);
  const [hideContent, setHideContent] = useState(false);

  return (
    <div id="home-root">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div class="modal-content-wrapper">
          <div class="close-icon">
            <img onClick={closeModal} src={closeIcon} />
          </div>
          <LazyLoadImage effect="blur" width={"100%"} src={getImg()} />
          <div class="modal-text-wrapper">{getRelatedText()}</div>
        </div>
      </Modal>

      <div
        onScroll={handleScroll}
        className={
          isMacintosh()
            ? "container both-scroll both-mandatory align-start"
            : "container"
        }
      >
        <header className="work-banner fullpage element">
          <div className="colorfultext">Work</div>
          <h2 className="web-only">
            We make products with
            <br /> business impact
          </h2>
          <h2 className="mobile-only">We make products with business impact</h2>
        </header>

        <div className="work-details-child odd-child fullpage element">
          <div id="image-first" onClick={clickHandler} className="mobile-only">
            <h3 style={{ pointerEvents: "none" }}>
              <b>Measuring the pulse </b> of a large transformation program
            </h3>
          </div>
          <div className="solution-description web-only">
            <p ref={first}>
              <div class="industry-info">Tech Enterprise</div>
              <h3>
                <b>Measuring the pulse</b> of a large transformation program
              </h3>
              <br />
              {hideContent !== 1 && (
                <>
                  Data from over 1,200 milestones, 30+ sources were{" "}
                  <b>crunched, simplified and visualized to a nutshell</b>, for
                  Executive consumption.
                  <br />
                  We make enterprise data useful and usable, by abstracting the
                  hidden story behind the data, and presenting it through
                  intuitive, interactive digital visualizations.
                  <br />
                </>
              )}
              <ContactUs
                title="Visualizing the pulse"
                setHideContent={setHideContent}
                index={1}
              ></ContactUs>
            </p>
          </div>
          <div ref={firstImage} id="pandemic" className="solution-img">
            <img className="lazy-images-odd" src={taf} />
          </div>
        </div>
        <div className="work-details-child even-child fullpage element">
          <div id="image-second" onClick={clickHandler} className="mobile-only">
            <h3 style={{ pointerEvents: "none" }}>
              <b>Keeping people out of the </b>hospitals
            </h3>
          </div>
          <div ref={secondImage} className="solution-img pandemic">
            <img className="lazy-images-even" src={pandemicImg} />
          </div>

          <div className="solution-description web-only">
            <p ref={second} className="opposite">
              <div class="industry-info">Healthcare</div>
              <h3>
                <b>Keeping people out of the </b>hospitals
              </h3>
              <br />
              {hideContent !== 2 && (
                <>
                  Our approach was to connect common health data and established
                  medical principles to proactively identify potential health
                  risks in individuals.
                  <b> We built a connected suite of apps</b> which listen to
                  health data of patients, risk stratify, and alert healthcare
                  providers ahead of time, to avoid potential hospitalizations
                  as much as possible.
                  <br />
                  This application is used for preventive care, senior care,
                  clinical trials, pandemic situations and many more.
                  <br />
                </>
              )}
              <ContactUs
                title="Keeping people out of the hospitals"
                setHideContent={setHideContent}
                index={2}
              ></ContactUs>
            </p>
          </div>
        </div>
        <div className="work-details-child odd-child fullpage element">
          <div id="image-third" onClick={clickHandler} className="mobile-only">
            <h3 style={{ pointerEvents: "none" }}>
              <b>Consumerization of Enterprise </b>Apps
            </h3>
          </div>
          <div className="solution-description web-only">
            <p ref={third}>
              <div class="industry-info">Cloud Security</div>
              <h3>
                <b>Consumerization of Enterprise </b>Apps
              </h3>
              <br />
              {hideContent !== 3 && (
                <>
                  A comprehensive <b>multi-cloud environment management tool</b>{" "}
                  with consumer-like user experience, designed for a leading
                  technology solutions provider.
                  <br />
                  Our team specializes in{" "}
                  <b>transforming complex business/technical functionalities</b>
                  into consumer-like experiences for enterprise applications.
                  <br />
                </>
              )}
              <ContactUs
                title="Consumerization of Enterprise Apps"
                setHideContent={setHideContent}
                index={3}
              ></ContactUs>
            </p>
          </div>
          <div ref={thirdImage} className="solution-img gta">
            <img className="lazy-images-odd" src={polycloud} />
          </div>
        </div>

        <div className="work-details-child even-child fullpage element">
          <div ref={fourthImage} className="solution-img fleet">
            <img className="lazy-images-even picarroimg" src={picarro} />
          </div>
          <div id="image-fourth" onClick={clickHandler} className="mobile-only">
            <h3 style={{ pointerEvents: "none" }}>
              <b>Digitizing Industrial </b>Products
            </h3>
          </div>
          <div className="solution-description web-only">
            <p ref={fourth} className="opposite">
              <div class="industry-info">Industrial Manufacturing</div>
              <h3>
                <b>Digitizing Industrial </b>Products
              </h3>
              <br />
              {hideContent !== 4 && (
                <>
                  Airborne molecular contaminants can seriously limit the
                  productivity rate in hi-tech manufacturing.
                  <b> Rhombuz designed the digital experience</b> for a leading
                  AMC monitoring system,
                  <b> making it intuitive, user-friendly, and efficient </b>,
                  resulting in overall productivity improvement in fab units.
                  <br />
                  We specialize in designing and building digital interfaces for
                  industrial products, for user-friendliness, productivity
                  improvement, and safety.
                  <br />
                </>
              )}
              <ContactUs
                title="Digitizing Industrial Products"
                setHideContent={setHideContent}
                index={4}
              ></ContactUs>
            </p>
          </div>
        </div>
        <div className="work-details-child odd-child fullpage element">
          <div className="solution-description web-only">
            <p ref={fifth}>
              <div class="industry-info">Health and Screening </div>
              <h3>
                <b>AI Powered </b>EHR
              </h3>
              <br />
              {hideContent !== 5 && (
                <>
                  AI powered Electronic Health Record system which provide
                  intelligent diagnosis and recommendations (prescriptions,
                  tests and treatment plans)
                  <div style={{ margin: "1em 0" }}>
                    <ul className="ul">
                      <li>
                        <b>50% reduction</b> in Physician-Patient encounter time
                      </li>
                      <li>
                        Improved the <b>quality and velocity</b> of healthcare
                        services
                      </li>
                      <li>
                        <b>New business models</b> for hospitals and clinics
                      </li>
                    </ul>
                    {/* - <b>50% reduction</b> in Physician-Patient encounter time
                    <br />- Improved the <b>quality and velocity</b> of
                    healthcare services
                    <br />- <b>New business models</b> for hospitals and clinics
                    <br /> */}
                  </div>
                  
                  <br />
                </>
              )}
              <ContactUs
                title="AI Powered EHR"
                setHideContent={setHideContent}
                index={5}
              ></ContactUs>
            </p>
          </div>
          <div ref={fifthImage} className="solution-img ehr">
            <img className="lazy-images-odd" src={ehrImg} />
          </div>
          <div id="image-fifth" onClick={clickHandler} className="mobile-only">
            <h3 style={{ pointerEvents: "none" }}>
              <b>AI Powered </b>EHR
            </h3>
          </div>
        </div>

        <div className="work-details-child even-child fullpage element">
          <div ref={sixthImage} className="solution-img fleet">
            <img className="lazy-images-even fleetimg" src={gtaImg} />
          </div>
          <div id="image-sixth" onClick={clickHandler} className="mobile-only">
            <h3 style={{ pointerEvents: "none" }}>
              <b>Global Trade Analysis </b>Tool
            </h3>
          </div>
          <div className="solution-description web-only">
            <p ref={sixth} className="opposite">
              <div class="industry-info">Policy Analysis</div>
              <h3>
                <b>Global Trade Analysis </b>Tool
              </h3>
              <br />
              {hideContent !== 6 && (
                <>
                  A tool for corporations and policy makers to{" "}
                  <b>analyze the economic impact</b> of global events, bilateral
                  agreements, political changes, pandemic etc. on their
                  business, region or country. The GTA tool combine global
                  standard data and user inputs to generate economic forecast
                  models for macro and micro level analysis. The tool is
                  especially useful for enterprises and SMEs to{" "}
                  <b>
                    assess the exposure of their business to global economic
                    shocks
                  </b>{" "}
                  and plan accordingly.
                  <br />
                  This product was developed in partnership with{" "}
                  <a href="http://www.infsum.com/en/" target="_blank">
                    {" "}
                    Infinite Sum Modeling
                  </a>{" "}
                  – a company focused on developing mathematical models for
                  economic analysis and forecasting.
                  <br />
                </>
              )}
              <ContactUs
                title="Global Trade Analysis"
                setHideContent={setHideContent}
                index={6}
              ></ContactUs>
            </p>
          </div>
        </div>

        <div className="grey element">
          <section className="content-section">
            <h5 className="top-pad-header">Business Inquiry</h5>
            <h3>What would you like to achieve ?</h3>
            <ul
              ref={acheive}
              className="impact impact-solve movable top-pad-3 "
            >
              <li className="solve wowhide">
                <div>
                  <h6>Improve bussiness efficiency</h6>
                  <p>
                    Are your current systems, processes slowing you down,
                    draining your precious resources?
                  </p>
                </div>
              </li>
              <li className="bvb wowhide">
                <div>
                  <h6>Build a new solution</h6>
                  <p>
                    Need help to ideate, translate your business need into an
                    innovative solution?
                  </p>
                </div>
              </li>
              <li className="reality wowhide">
                <div>
                  <h6>Take an idea to reality</h6>
                  <p>
                    Want to move quickly on your innovation goals, accelerate
                    go-to-market of your ideas?
                  </p>
                </div>
              </li>
            </ul>

            <button onClick={toContactPage}>Lets start a conversation</button>
          </section>
        </div>
        <div className="white-bg relative  padding-bottom-3">
          <section className="content-section element">
            <div class="announcemnet-cover"></div>
            <div className="listening-triangle"></div>
            <div className="announcement"></div>
            <h3 className="top-pad-header top-pad-header-mobile hiring-top">
              We are hiring
            </h3>
            <h5>Seattle / Remote</h5>
            <div className="margintop5 movable">
              We are a team of designers, data scientists, programmers,
              researchers <br /> who work together to build product eco-systems
              with business impact for our clients.
              <br />
              <br />
              If your life mission is to go beyond the obvious, <br /> and
              create something amazing ...
            </div>

            <button onClick={toContactPage}>Lets talk!</button>
          </section>
        </div>

        <Footer />
      </div>
    </div>
  );
}
