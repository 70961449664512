
import React, { useState, useEffect }  from 'react'
const isScrollingup = (prevscrollval, newscrollval) => { return prevscrollval > newscrollval};
const handleHeaderScroll = (y, e, elem) => {
    if ( isScrollingup(y, e) && y > 100) {
        document.getElementById("header").className = "roll-downheader"
        
        //after 2 sec hide
      } else if (!isScrollingup(y, e)  && y > 100) {
        document.getElementById("header").className = "roll-upheader"
      }
      if(y < 100)
      {
        document.getElementById("header").className = ""
      }
}






export default  {"isScrollingup" : isScrollingup, "handleHeaderScroll" : handleHeaderScroll};