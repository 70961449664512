const EMAIL_BASE_URL = "https://www.rhombusapi.com/api/v1/email";

export const send_email = (params, body) => {
  return fetch(
    `${EMAIL_BASE_URL}?toEmail=${params.toEmail}&message=${
      params.message
    }&name=${params.name}${params.title && "&title=" + params.title}`,
    { ...body }
  );
};
