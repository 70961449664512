


const setAnimationScrolldelay = (parent) => {
    
    var elems = Array.from(parent.current.children);
    
    elems.forEach((element, index) => {
        let delay = index * 0.1 + 0.1;
        element.style.transitionDelay = delay + "s";
    });

}

const wowanimatefadein = (parent, complexel) => {
    var elems = [parent.current];
    if (complexel)
    elems = Array.from(parent.current.children);
    
    elems.forEach((element) => {

        let oldclass = element.className;
        if(!element.classList.contains("wowup"))
        element.className= oldclass + " wowup";
        //element.style.transform = "translate(0, 0)"
    });
}
const wowanimatefadeout = (parent, complexel) => {
    var elems = [parent.current];
    if (complexel)
    elems = Array.from(parent.current.children);
    
    elems.forEach((element) => {
        if(element.classList.contains("wowup"))
        element.className = element.className.replace("wowup","")
        //element.style.transform = "translate(0, 0)"
    });
}


export default {"setAnimationScrolldelay":setAnimationScrolldelay, "wowanimatefadein":wowanimatefadein,"wowanimatefadeout":wowanimatefadeout};