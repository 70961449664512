import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import "./../../styles/main.web.css";
import questionare from "./../../questionnaire.json";
import {send_email} from "../../service/services";

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {businessSet,businessEmailSet,hiringSet,hiringEmailSet,hiSet} from './steps';
import Modal from '@mui/material/Modal';
import ReactHtmlParser from 'react-html-parser'


 const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#c4c4c4',
    '&:hover': {
      backgroundColor: '#c4c4c4',
      opacity:0.8
    },
  }));
 

export default function VerticalLinearStepper(data) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [ques,setques]=React.useState(questionare);
  const emailId = React.useRef(null);
  const name = React.useRef(null);
  const {answer,setanswer}=data;
  const {submit,setSubmit}=data;
  const [selectedChoice,setSelectedChoice]=React.useState([]);
  const [nameValue,setNameValue]=React.useState('');
  const [emailValue,setEmailValue]=React.useState('');
  const emailregex=new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    const submitdata = ()=>{
        setSubmit(true);
    send_email({toEmail:emailId.current.value,message:answer.text,name:name.current.value},{}).then(res=>{
        console.log("email sent successfully");
       
    }).catch((e)=>{
        console.log("error happened")
    })
    
    }

  const feedbackHandler=(e)=>{
    setanswer({...answer,text:e.target.value});
  }

  const handleNext = (choice) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setanswer({...choice})
    let choiceArr=[...selectedChoice,choice.heading];
    setSelectedChoice(choiceArr);
    console.log(selectedChoice,"selectedChoice");
    if(choice.id){
        switch(choice.id){
            case 1:setques([...ques,...businessSet]) 
                    break;
            case 2:setques([...ques,...hiringSet]) 
                    break;
            case 3:setques([...ques,...hiSet])
                    break;
        }}else
        {
            switch(choice.followquestionid){
                case 3:setques([...ques,...businessEmailSet]) 
                        break;
                case 4:setques([...ques,...hiringEmailSet]) 
                        break;
                case 3:setques([...ques,...hiSet])
                        break;
            }

        }
    
  };

  const inputHandler=()=>{
    console.log(emailregex.test(emailId.current.value))
    setNameValue(name.current.value);
    setEmailValue(emailId.current.value);
  }

  const handleBack = (step,index) => {
      setNameValue('');
      setEmailValue('');
      if(step.id!=null && step.id!=activeStep){
        
        let newArr;
        let newChoiceArr;
        newArr=ques.slice(0,step.id+1);
        if(step.id===0){
          setSelectedChoice([]);
        }if(step.id===1){
          selectedChoice.pop();
          setSelectedChoice([...selectedChoice]); 
        }
        setques(newArr);
        setActiveStep(step.id);
        
        
      }
    
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getLabel = (step) =>{
      return (step.question);
  }

  return (
    <Box>
     
    
    <Fade in={submit} timeout={1000}>
        <div class='sticky-overlaywrapper'>
          <div className='overlay'></div>
          <div className="thanksyou-wrapper">
              <div className="colorfultext thankyou-font">Thank you!</div>
              <p>Thank you for connecting with us. We shall get back to you soon. Please write to <a href="mailto:hello@rhombuz.io" class="mailbox-nomail">hello@rhombuz.io</a> for any additional information.</p>
              <ColorButton onClick={()=> window.location.href='/'} variant="contained">OK</ColorButton>
              </div>
        </div>
    </Fade>
       
    <Fade in={!submit} timeout={800}>
      <Stepper  activeStep={activeStep} orientation="vertical">
        {ques.map((step, index) => (
          <Step key={step.label}>
            <StepLabel onClick={()=>{handleBack(step,index)}}>{
              
             index<activeStep? selectedChoice[index]:ReactHtmlParser(step.question)
              }</StepLabel>
            <Fade in={activeStep==index} timeout={600}>
            <StepContent transitionDuration={700} TransitionProps={{ unmountOnExit: false }}>
              
              <Box sx={{ mb: 2 }}>
                
                {step.choices?.map((nextChoice,nextIndex)=>(
                    <div className='nextChoice-container' key={index}  onClick={()=>{handleNext(nextChoice)}}>
                  <div >
                    <h5>{nextChoice.heading}</h5>
                    <div>{nextChoice.text}</div>
                  </div>
                    </div>
                ))}
                {step.email?<div className="relative contactus-form">
                  <input onChange={inputHandler} ref={name} placeholder='Name' type="name" class="user-box"/>
                  <input onChange={inputHandler} placeholder='Email id' ref={emailId} type="email" class="email-box"/> 
                  <button onClick={submitdata} type="submit" className={`email-submit ${(nameValue.trim()!=='' && emailValue.trim()!=='' && emailregex.test(emailValue))?'active':''}`}>SEND</button></div>:''}
                {
                    step.textarea?
                    <div>
                    <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder='Feedback'
                    className='say-hi-class'
                    onChange={feedbackHandler}
                  /><div className="relative contactus-form">
                    <input onChange={inputHandler} ref={name} placeholder='Name' type="name" class="user-box"/>
                    <input onChange={inputHandler} ref={emailId} placeholder='Email id' type="email" class="email-box"/> 
                    
                  <button onClick={submitdata} type="submit" className={`email-submit ${(nameValue.trim()!=='' && emailValue.trim()!=='' && emailregex.test(emailValue))?'active':''}`}>SEND</button></div>
                  </div>
                  :''
                }
              </Box>
            </StepContent>
            </Fade>
          </Step>
        ))}
      </Stepper>
    </Fade>
    </Box>
  );
}
