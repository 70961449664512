import React, { useState, useEffect } from "react";

import { Route, Switch } from "react-router-dom";

// We will create these two pages in a moment

import HomePage from "./pages/HomePage";
import Header from "./components/header/header";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Work from "./pages/Work";
import "./App.css";

function App() {
  useEffect(() => {
    // if(window.location.href.indexOf("/contact") !==  -1){
    //   document.getElementById("header").style.backgroundColor = "#131313"
    // }

    if (window.matchMedia("(min-width: 760px)").matches) {
      let mousePosX = 0,
        mousePosY = 0,
        mouseCircle = document.getElementById("mouse-circle");

      document.onmousemove = (e) => {
        mousePosX = e.pageX;
        mousePosY = e.pageY;
        if (
          document
            .elementFromPoint(e.clientX, e.clientY)
            ?.classList.contains("hovertrigger")
        ) {
          mouseCircle.style.transform = "scale(1.4)";
        } else {
          mouseCircle.style.transform = "scale(1)";
        }
        if (isOutOfViewport(mouseCircle).any) {
          mouseCircle.style.transform = "scale(0)";
        }
      };

      let delay = 6,
        revisedMousePosX = 0,
        revisedMousePosY = 0;

      function delayMouseFollow() {
        requestAnimationFrame(delayMouseFollow);

        revisedMousePosX += (mousePosX - revisedMousePosX) / delay;
        revisedMousePosY += (mousePosY - revisedMousePosY) / delay;

        mouseCircle.style.top = revisedMousePosY + "px";
        mouseCircle.style.left = revisedMousePosX + "px";
      }
      delayMouseFollow();
    }
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false);
  }, [window.screen.width]);
  let checker = (arr) => arr.every((v) => v === false);

  const [isMobile, setIsMobile] = useState(false);
  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false);
  }
  var isOutOfViewport = function (elem) {
    // Get element's bounding
    var bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    var out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom =
      bounding.bottom >
      (window.innerHeight || document.documentElement.clientHeight);
    out.right =
      bounding.right >
      (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

    return out;
  };
  window.onresize = detectWindowSize;
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/work" component={Work} />
      </Switch>
      {!isMobile && <div id="mouse-circle"></div>}
    </div>
  );
}

export default App;
