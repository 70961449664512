import React, { useEffect, useState } from "react";

import "./header.css";
export default function Header() {
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [work, setWork] = useState(false);
  const [contact, setContact] = useState(false);
  useEffect(() => {
    switch (window.location.pathname) {
      case "/contact":
        setContact(true);
        break;
      case "/about":
        setAbout(true);
        break;
      case "/work":
        setWork(true);
        break;
      default:
        setHome(true);
        break;
    }
  }, []);
  return (
    <>
      <nav id="header">
        <p
          className="logo hovertrigger"
          onClick={() => (window.location.href = "/")}
        ></p>
        <ul className="nav web-only">
          <li
            onClick={() => {
              window.location.href = "/";
            }}
            className={"hovertrigger " + (home ? "active-menu" : "")}
          >
            home
          </li>
          <li
            onClick={() => {
              window.location.href = "/about";
            }}
            className={"hovertrigger " + (about ? "active-menu" : "")}
          >
            about
          </li>
          <li
            onClick={() => {
              window.location.href = "/work";
            }}
            className={"hovertrigger " + (work ? "active-menu" : "")}
          >
            work
          </li>
          <li
            onClick={() => {
              window.location.href = "/contact";
            }}
            className={"hovertrigger " + (contact ? "active-menu" : "")}
          >
            contact
          </li>
        </ul>

        <div id="menuToggle" className="mobile-only">
          <input type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <a href="/">
              <li>Home</li>
            </a>
            <a href="/about">
              <li>About</li>
            </a>
            <a href="/work">
              <li>Work</li>
            </a>
            <a href="/contact">
              <li>Contact</li>
            </a>
          </ul>
        </div>
      </nav>
    </>
  );
}
