import React, { useState, useEffect, useRef } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import Footer from "../components/footer/footer";
import utils from "../components/utils";
import anim from "../components/masonry/animation";
export default function About() {
  const acheive = useRef(null);
  const [scroll, setScroll] = useState(false);
  const [scroll1, setScroll1] = useState(false);
  const [scroll2, setScroll2] = useState(false);
  const [scroll3, setScroll3] = useState(false);
  const [y, setY] = useState(0);
  const [currdirforward, setcurrdirforward] = useState("forward");
  var prevdir = "back";
  useEffect(() => {
    anim.setAnimationScrolldelay(acheive);

    document.documentElement.style.overflowY = "scroll";
    return () => {
      document.documentElement.style.overflow = "hidden";
    };
  }, []);
  var approxliwidth = 350;
  window.addEventListener("scroll", () => {
    setScroll(window.scrollY > window.innerHeight * 1.8);
    setScroll1(window.scrollY > window.innerHeight * 1.8 + approxliwidth);
    setScroll2(window.scrollY > window.innerHeight * 1.8 + approxliwidth * 2);
    setScroll3(window.scrollY > window.innerHeight * 1.8 + approxliwidth * 3);
    if (y - window.screenY > 50) setcurrdirforward("forward");
    else if (y - window.screenY < -20) setcurrdirforward("back");
    if (Math.abs(y - window.scrollY) > 100 && currdirforward !== prevdir) {
      utils.handleHeaderScroll(y, window.scrollY);
      setY(window.scrollY);
      prevdir = currdirforward;
    }
    if (window.scrollY > window.innerHeight * 5.6)
      anim.wowanimatefadein(acheive, true);
    else anim.wowanimatefadeout(acheive, true);
  });

  const toContactPage = (e) => {
    window.location.href = "/contact";
  };

  return (
    <div id="subroot">
      <header className="work-banner">
        <div className="colorfultext">About</div>
        <h2 className="web-only">
          We are a data-first <br />
          product company
        </h2>
        <h2 className="mobile-only">
          We are a data-first <br />
          design company
        </h2>
      </header>
      <div className="white-bg black-rhombuz">
        <div className="seperator"></div>
        <section className="fullpage element content-section lefttext">
          <div className="flex-row">
            <div>
              <p className="form-function-text">
                Form follows function, <br />
                Function follows form,
              </p>
              <h3 className="bold">
                {" "}
                Our products <br />
                follow data
              </h3>
            </div>
            <div className="seperator-right">
            Data serves as the essential foundation for today's digital ecosystems. Unlike a few years ago, real-time data now underpins every facet of business operations, including strategy, customer experience, service excellence, security, compliance, and operations. Organizations that effectively leverage this data-driven ecosystem tend to achieve higher efficiency and profitability.
              <br />
              <br />
              Rhombuz applies the classic design process on data, to create products that deliver business impact to our clients and enable them to lead through innovation
            </div>
          </div>
        </section>
        <section className="content-section seperator-friendly">
          <h3>
            A true partner <br /> for digital innovation
          </h3>
          <div>Rhombuz can take your ideas to reality </div>
        </section>
        <section className="element content-section">
          <div className="left-of-seperator">
            <div className={`item ${scroll ? "item-display" : ""}`}>
              <h4>Product Strategy</h4>
              <p>
                We go deep into our client’s business objectives, users, markets
                and data to abstract patterns of opportunities, and convert them
                into tangible product ideas, roadmap.
              </p>
              <p className="services-text">
                Research (User, Market)
                <br />
                Data analysis
                <br />
                Ideation, concept development
                <br />
                Future state prototypes
                <br />
                Product Roadmap
                <br />
              </p>
            </div>
          </div>

          <div className="right-of-seperator">
            <div
              className={`item-right ${scroll1 ? "item-display-right" : ""}`}
            >
              <h4>Product Design</h4>
              <p>
                Our design process connect the dots between user needs, business
                priorities and data, to build digital eco-systems that help our
                clients to leapfrog in business innovation
              </p>
              <p className="services-text">
                Information Architecture
                <br />
                UX Design
                <br />
                Interactive Prototypes
                <br />
                User/ Business Validation
              </p>
            </div>
          </div>

          <div className="left-of-seperator third-left-child">
            <div className={`item ${scroll2 ? "item-display" : ""}`}>
              <h4>Product Development</h4>
              <p>
              Great ideas are as good as how they are executed. Our product experts collaborate closely with our clients' technology teams and partners to fully realize the product vision
              </p>
              <p className="services-text">
                Product Architecture
                <br />
                Data collection & integration
                <br />
                Development
                <br />
                Integration
                <br />
                Testing, Validation
              </p>
            </div>
          </div>

          <div className="right-of-seperator">
            <div
              className={`item-right ${scroll3 ? "item-display-right" : ""}`}
            >
              <h4>Artificial Intelligence</h4>
              <p>
              Our product design strategy is data-centric and powered by cutting-edge AI/ML technologies, enabling us to create innovative business solutions and immersive experiences.
              </p>
              <p className="services-text">
                Automation
                <br/>
                Predictive Analytics
                <br/>
                Data Capture/OCR
                <br/>
                Custom Models
                <br/>
                Enterprise AI Solutions
                <br/>
              </p>
            </div>
          </div>
        </section>
        <div className="about-done-that">
          <section className="content-section ">
            <h3>
              Our team has delivered over <br />
              <b>100+ products across major industry sectors</b>
            </h3>
            {/* <p className="top-pad-1 movable">Our team team of experts (design, research, ML, product engineering) has a collective experience in designing, building and launching 100+ complex products across major industry sectors.</p> */}
            {/* <ul className="nav nav-top-pad row-ul-mobile">
        <li>Telecom</li>
        <li>Supply Chain</li>
        <li>Executive Insights</li>
    </ul>
    <ul className="nav row-ul-mobile">
        <li>Retail</li>
        <li>Banking</li>
        <li>Healthcare</li>
    </ul>
    <ul className="nav row-ul-mobile"> 
        <li>Cyber Security</li>
        <li>Circular Economy</li>
        <li>Automotive</li>
    </ul>*/}
            <div className="about-map-img"></div>
          </section>
        </div>
        <div className="about-black">
          <section className="content-section web-only">
            {/* <div className="nav-top-pad reasons-heading movable">We leverage our experience to help our clients to achieve their innovation goals, in the most practical, effective and sustainable way.</div> */}
            <h3 className="bold ">5 reasons to engage Rhombuz </h3>
            <div>
              {" "}
              as your <b>product design partner</b>{" "}
            </div>
            <div className="reasons"></div>
          </section>
        </div>
        <section className="reason-mobile mobile-only">
          <div class="reason-heading-mobile">
            <b className="font-5-reasons">5 reasons to engage</b>
            <br /> with <b>Rhombuz</b> as your product design partner
          </div>
          <div class="triangle-right">
            <h4>
              <b>Innovation</b>
            </h4>
            <p>We are not afraid to challenge the status quo</p>
          </div>
          <div class="text-align-right triangle-left">
            <h4>
              <b>User Delight</b>
            </h4>
            <p>True customer delight is how it works</p>
          </div>
          <div class="triangle-right">
            <h4>
              <b>Speed</b>
            </h4>
            <p>Every brilliant idea has a shelf life</p>
          </div>
          <div class="text-align-right triangle-left">
            <h4>
              <b>Quality</b>
            </h4>
            <p>One home run is always better than two doubles</p>
          </div>
          <div class="triangle-right">
            <h4>
              <b>True Partner</b>
            </h4>
            <p>Innovation is an unending journey</p>
          </div>
        </section>
        <div className="grey element">
          <section className="content-section">
            <h5 className="top-pad-header">Business Inquiry</h5>
            <h3>What would you like to achieve ?</h3>
            <ul
              ref={acheive}
              className="impact impact-solve movable top-pad-3 "
            >
              <li className="solve wowhide">
                <div>
                  <h6>Improve bussiness efficiency</h6>
                  <p>
                    Are your current systems, processes slowing you down,
                    draining your precious resources?
                  </p>
                </div>
              </li>
              <li className="bvb wowhide">
                <div>
                  <h6>Build a new solution</h6>
                  <p>
                    Need help to ideate, translate your business need into an
                    innovative solution?
                  </p>
                </div>
              </li>
              <li className="reality wowhide">
                <div>
                  <h6>Take an idea to reality</h6>
                  <p>
                    Want to move quickly on your innovation goals, accelerate
                    go-to-market of your ideas?
                  </p>
                </div>
              </li>
            </ul>

            <button onClick={toContactPage}>Lets start a conversation</button>
          </section>
        </div>

        <section className="content-section element padding-bottom-3">
          <div class="announcemnet-cover"></div>
          <div className="listening-triangle"></div>
          <div className="announcement"></div>
          <h3 className="top-pad-header top-pad-header-mobile hiring-top">
            We are hiring
          </h3>
          <h5>Seattle / Remote</h5>
          <div className="margintop5 movable">
            We are a team of designers, data scientists, programmers,
            researchers <br /> who work together to build product eco-systems
            with business impact for our clients.
            <br />
            <br />
            If your life mission is to go beyond the obvious, <br /> and create
            something amazing ...
          </div>

          <button onClick={toContactPage}>Lets talk!</button>
        </section>
      </div>
      <Footer />
    </div>
  );
}
