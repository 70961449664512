import React, { useEffect, useState, useRef } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import utils from "../components/utils";
import portfolio3 from "../img/img_hospitals_web_home.png";
import portfolio6 from "../img/img_trade_policy-min.png";
import portfolio5 from "../img/img_pandemic_web_home.png";
import portfolio4 from "../img/img_smart_ehr-min.png";
import portfolio1 from "../img/img_TAF_web_home.png";
import portfolio2 from "../img/img_executive_insights-min.png";
import scrollDown from "../img/scroll-down-chevron.svg";

import anim from "../components/masonry/animation";
export default function HomePage() {
  const magicsection = useRef(null);
  const magiccontent = useRef(null);
  const acheive = useRef(null);
  const wall = useRef(null);
  const ecoheading = useRef(null);
  const ecolist = useRef(null);
  const [y, setY] = useState(0);
  useEffect(() => {
    anim.setAnimationScrolldelay(wall);
    anim.setAnimationScrolldelay(acheive);
    anim.setAnimationScrolldelay(ecolist);
  }, []);

  const handleScroll = (e) => {
    var top = e.target.scrollTop;
    var VH = window.innerHeight;
    const elems = Array.from(wall.current.children);
    utils.handleHeaderScroll(y, top);
    setY(top);

    if (top > VH / 2 && top < VH * 1.6) {
      magicsection.current.className = "magic-section-animate";
      magiccontent.current.className =
        "magic-content web-only magic-section-animate";
      document.getElementById("root").className = "magic-root-before";
    } else {
      magicsection.current.className = "";
      magiccontent.current.className = "magic-content web-only";
    }
    if (top > VH * 2 - 100) {
      anim.wowanimatefadein(ecoheading, false);
      anim.wowanimatefadein(ecolist, true);
    } else {
      anim.wowanimatefadeout(ecoheading, false);
      anim.wowanimatefadeout(ecolist, true);
    }
    if (top > VH * 3 - 100) {
      elems.forEach((element) => {
        element.style.transform = "translate(0, 0)";
        element.style.opacity = "1";
      });
    } else {
      elems.forEach((element) => {
        element.style.transform = "translate(0, 180px)";
        element.style.opacity = "0";
      });
    }
    // 0.4 changed to 0.7 for full screen in 13' mac, but not sure what is the logic behind this code
    if (top > VH * 5 - VH * 0.7 - 105) {
      console.log("up");
      anim.wowanimatefadein(acheive, true);
    } else {
      console.log("down");
      anim.wowanimatefadeout(acheive, true);
    }
    if (top < 600) document.getElementById("root").className = "";
  };
  //  const isDeviceiPad = () => {
  //   return navigator.platform.match(/iPad/i);
  // }
  const toContactPage = (e) => {
    window.location.href = "/contact";
  };
  const toWorkPage = (e) => {
    window.location.href = "/work";
  };

  return (
    <div id="home-root">
      <div
        onScroll={handleScroll}
        className="container both-scroll both-mandatory align-start"
      >
        <header className="banner fullpage element">
          <div className="scroll-down">
            <p>Scroll Down</p>
            <img src={scrollDown} />
          </div>
          {/* <Homeimg /> */}
          <h1 data-scroll-speed="3">Digital Fusion</h1>
          <ul data-scroll-speed="2" className="nav fusion">
            {/* <li>
              <p className="transparent">useraaesearch</p>
              <div className="animated-title">
                <div className="text-top">
                  <div>
                    <span>user research</span>
                  </div>
                </div>
              </div>
            </li> */}
            <li>
              <p className="transparent">data</p>
              <div className="animated-title second">
                <div className="text-top">
                  <div>
                    <span>data</span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p className="transparent">design</p>
              <div className="animated-title third">
                <div className="text-top">
                  <div>
                    <span>design</span>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <p className="transparent">engineering</p>
              <div className="animated-title fourth">
                <div className="text-top">
                  <div>
                    <span>engineering</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </header>
        <section className="fullpage element">
          <div className="flex-row">
            <div className="magic-section" data-scroll-speed="3">
              <h3 ref={magicsection}>
                The magic is when <b>design thinking</b> meets{" "}
                <b>data science</b>
              </h3>
              <p className="mobile-only">
                The future belongs to eco-systems of data-driven, intelligent
                things which can continuously adapt to changes, and create new
                possibilities for mankind.
                <br />
                <br />
                At Rhombuz, we fuse <b>design thinking </b> and{" "}
                <b>data science</b> to reimagine the way we interact with
                digital systems.
              </p>
            </div>
            <div
              ref={magiccontent}
              className="magic-content web-only"
              data-scroll-speed="2"
            >
              The future belongs to eco-systems of data-driven, intelligent
              things which can continuously adapt to changes, and create new
              possibilities for mankind.
              <br />
              <br />
              At Rhombuz, we fuse <b>design thinking </b> and{" "}
              <b>data science</b> to reimagine the way we interact with digital
              systems.
            </div>
          </div>
        </section>
        <div className="white-bg">
          <section
            className="content-section fullpage element top-pad-header"
            data-scroll-speed="2"
          >
            <div className="movable">
              <h3 ref={ecoheading} className="wowhide">
                Product eco-systems <br />
                with <b>measurable impact</b>
              </h3>
            </div>
            <div className="movable">
              {" "}
              We partner with our clients to develop data-driven solutions that deliver measurable business benefits and provide exceptional customer experiences
              {" "}
            </div>
            <ul ref={ecolist} className="impact movable ">
              <li className="etools wowhide">
                <div>
                  <h6 className="text-uppercase">Enterprise </h6>
                  <p>
                    Business innovation, <br />
                    Operational efficiency
                  </p>
                </div>
              </li>
              <li className="capps wowhide">
                <div>
                  <h6 className="text-uppercase">Consumer </h6>
                  <p>
                    Product acceptance, <br />
                    Consumer delight
                  </p>
                </div>
              </li>
              <li className="pauto wowhide">
                <div>
                  <h6 className="text-uppercase"> Automation</h6>
                  <p>
                    Process Optimization,
                    <br /> Customer Service
                  </p>
                </div>
              </li>
              <li className="monitor wowhide">
                <div>
                  <h6 className="text-uppercase">Monitoring</h6>
                  <p>
                    Strategic forecasting, <br /> Executive Insights
                  </p>
                </div>
              </li>
            </ul>
          </section>
        </div>
        <div className="element allow-scroll ">
          <div className="scrollable-div">
            <section className="scalable">
              <div className="content-section">
                <h3 className="mobile-top-spacing">
                  <b>Smart, usable products</b> 
                 
                </h3>

                <div ref={wall} className="masonry-container element web-only">
                  <div className="masonry-item">
                    <div onClick={toWorkPage}>
                      <img src={portfolio1} className="hovertrigger" />
                    </div>
                  </div>
                  <div className="masonry-item">
                    <div onClick={toWorkPage}>
                      <img src={portfolio2} className="hovertrigger" />
                    </div>
                  </div>
                  <div className="masonry-item">
                    <div onClick={toWorkPage}>
                      <img src={portfolio3} className="hovertrigger" />
                    </div>
                  </div>
                  <div className="masonry-item">
                    <div onClick={toWorkPage}>
                      <img src={portfolio4} className="hovertrigger" />
                    </div>
                  </div>

                  <div className="masonry-item">
                    <div onClick={toWorkPage}>
                      <img src={portfolio5} className="hovertrigger" />
                    </div>
                  </div>
                  <div className="masonry-item">
                    <div onClick={toWorkPage}>
                      <img
                        src={portfolio6}
                        className="hovertrigger masonry-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="products-home mobile-only">
                  <div onClick={toWorkPage}>
                    <img src={portfolio1} />
                  </div>
                  <div onClick={toWorkPage}>
                    <img src={portfolio2} />
                  </div>
                  <div onClick={toWorkPage}>
                    <img src={portfolio3} />
                  </div>
                  <div onClick={toWorkPage}>
                    <img src={portfolio4} />
                  </div>
                  <div onClick={toWorkPage}>
                    <img src={portfolio5} />
                  </div>
                  <div onClick={toWorkPage}>
                    <img src={portfolio6} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="grey element">
          <section className="content-section">
            <h5 className="top-pad-header">Business Inquiry</h5>
            <h3>What would you like to achieve ?</h3>
            <ul
              ref={acheive}
              className="impact impact-solve movable top-pad-2 "
            >
              <li className="solve wowhide">
                <div>
                  <h6>Improve business efficiency</h6>
                  <p>
                    Are your current systems, processes slowing you down,
                    draining your precious resources?
                  </p>
                </div>
              </li>
              <li className="bvb wowhide">
                <div>
                  <h6>Build a new solution</h6>
                  <p>
                    Need help to ideate, translate your <br />
                    business need into an innovative solution?
                  </p>
                </div>
              </li>
              <li className="reality wowhide">
                <div>
                  <h6>Take an idea to reality</h6>
                  <p>
                    Want to move quickly on your innovation goals, accelerate
                    go-to-market of your ideas?
                  </p>
                </div>
              </li>
            </ul>
            <button onClick={toContactPage}>Lets start a conversation</button>
          </section>
        </div>
        <div className="white-bg relative  padding-bottom-3">
          <section className="content-section element">
            <div className="announcemnet-cover"></div>
            <div className="listening-triangle"></div>
            <div className="announcement"></div>
            <h3 className="top-pad-header top-pad-header-mobile hiring-top">
              We are hiring
            </h3>
            <h5>Seattle / Remote</h5>

            <div className="margintop5 movable">
              We are a team of designers, data scientists, programmers,
              researchers <br /> who work together to build product eco-systems
              with business impact for our clients.
              <br />
              <br />
              If your life mission is to go beyond the obvious, <br /> and
              create something amazing ...
            </div>
            <button onClick={toContactPage}>Lets talk!</button>
          </section>
        </div>
        <footer className="element">
          <div className="footer-contact">
            <div>© 2021-22 RHOMBUZ LLC. All Rights Reserved</div>
            <a href="mailto:hello@rhombuz.io" className="mailbox">
              hello@rhombuz.io
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}
