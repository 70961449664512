import React, { useEffect, useState } from "react";
import "./contactus.css";
import CloseIcon from "../../img/icons_cross.svg";
import SendIcon from "../../img/send-icon.svg";
import SendIconActive from "../../img/send-icon-active.svg";
import { send_email } from "../../service/services";
import useDelayUnmount from "../../hooks/useDelayUnmount";

const ContactUs = ({ title, setHideContent, index }) => {
  const [showTextbox, setShowTextbox] = useState(false);
  const [email, setEmail] = useState("");
  const [sentSuccess, setSentSuccess] = useState(false);
  const [submit, setSubmit] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };
  const handleSendMail = () => {
    if (!validateEmail(email)) return alert("Email not valid");
    send_email({ toEmail: email, message: title, name: "" }, {})
      .then((res) => {
        console.log("res :>> ", res);
        console.log("email sent successfully");
        setSubmit(true);
        setSentSuccess(true);
      })
      .catch((e) => {
        setSubmit(true);
        setSentSuccess(false);
        console.log("error happened");
      });
  };
  const [valid, setValid] = useState(false);
  const updateInputValue = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSuccess = () => {
    setSentSuccess(false);
    setHideContent(false);
  };

  const toggleTextbox = (flag) => {
    setShowTextbox(flag);
    setHideContent(flag ? index : false);
  };
  const showDiv = useDelayUnmount(showTextbox, 450);
  const mountedStyle = { animation: "inAnimation 450ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 470ms ease-out",
    animationFillMode: "forwards",
  };

  useEffect(() => {
    setValid(validateEmail(email));
  }, [email]);

  return (
    <div style={{ height: "130px" }} className="contactus-container">
      {!submit ? (
        !showTextbox ? (
          <div
            className="contactus-text hovertrigger"
            onClick={() => toggleTextbox(true)}
          >
            <span className="hovertrigger">Contact us,</span> to know more/see
            demo
          </div>
        ) : (
          showDiv && (
            <div
              className={"contactus-textbox-container"}
              style={showTextbox ? mountedStyle : unmountedStyle}
            >
              <img
                src={CloseIcon}
                alt="closeIcon"
                onClick={() => toggleTextbox(false)}
                className="contactus-close-icon hovertrigger"
              />
              <div className="contactus-label-text">
                Want to learn more about this solution,<br></br>
                <b>Request a demo ?</b>
              </div>
              <div className="contactus-input-container">
                {!valid ? (
                  // <SendIcon className="contactus-send-icon inactive"></SendIcon>
                  <img
                    src={SendIcon}
                    alt="inactive"
                    className="contactus-send-icon inactive"
                  />
                ) : (
                  <img
                    src={SendIconActive}
                    alt="inactive"
                    className="contactus-send-icon hovertrigger"
                    onClick={handleSendMail}
                  />
                )}
                <input
                  type="email"
                  name="email"
                  id=""
                  placeholder="Please share your official email ID"
                  value={email}
                  onChange={(evt) => updateInputValue(evt)}
                  onBlur={(evt) => updateInputValue(evt)}
                />
              </div>
            </div>
          )
        )
      ) : sentSuccess ? (
        <div className="contactus-textbox-container">
          <div className="contactus-success-header"> Thank you!</div>
          <div className="contactus-success-text">
            We shall get back to you soon!
          </div>
          <div className="contactus-success-toggle" onClick={handleSuccess}>
            <span>OK</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ContactUs;
// function useDelayUnmount(isMounted, delayTime) {
//   const [showDiv, setShowDiv] = useState(false);
//   useEffect(() => {
//     let timeoutId;
//     if (isMounted && !showDiv) {
//       setShowDiv(true);
//     } else if (!isMounted && showDiv) {
//       timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
//     }
//     return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
//   }, [isMounted, delayTime, showDiv]);
//   return showDiv;
// }
