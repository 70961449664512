import React, { useEffect, useState } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
// import { Widget } from '@typeform/embed-react'
// import Questionnaire from "../components/questionnaire/questionnaire";
import VerticalLinearStepper from "../components/stepper";
import Fade from "@mui/material/Fade";
import Footer from "../components/footer/footer";
import utils from "../components/utils";

export default function Contact() {
  const [level, setLevel] = useState([0]);
  const [answer, setanswer] = useState({});
  const [submit, setSubmit] = useState(false);
  const movetonext = function (obj) {
    console.log(obj.followquestionid);
    if (level.length < 5)
      setLevel((oldArray) => [...oldArray, obj.followquestionid]);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        document.getElementById("header").style.backgroundColor = "#131313";
      } else {
        document.getElementById("header").style.backgroundColor = "transparent";
      }
    });

    document.documentElement.style.overflow = "hidden";
  });
  const [y, setY] = useState(0);
  const handleScroll = (e) => {
    var top = e.target.scrollTop;
    var VH = window.innerHeight;
    utils.handleHeaderScroll(y, top);
    setY(top);
  };
  return (
    <div
      className="container both-scroll both-mandatory align-start"
      onScroll={handleScroll}
    >
      <Fade in={!submit} timeout={800}>
        <header className="contact-banner element">
          <div className="colorfultext">Contact</div>
          <h2>Let's start a conversation</h2>
        </header>
      </Fade>
      <div className="custom-questionnaire element ">
        {level.map(function (val, index) {
          //if(index == (level.length-1))
          return (
            <VerticalLinearStepper
              submit={submit}
              setSubmit={setSubmit}
              setanswer={setanswer}
              answer={answer}
              level={val}
              movetonext={movetonext}
              length={level.length - 1}
            />
          );
        })}
      </div>

      <Footer />
    </div>
  );
}
